import './ParticipantItem.scss';
import React, {ChangeEvent, FC, ReactNode, useState} from 'react';
import Avatar from "../../atoms/Avatar/Avatar";
import Tag from "../../atoms/Tag/Tag";
import {getStatusParticipant, getTypeAttend} from "../../../helpers/functions";
import EditIcon from "../../atoms/icons/EditIcon";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import DeleteIcon from "../../atoms/icons/DeleteIcon";


interface IParticipantItem{
    isCheck?:boolean;
    id?:number;
    userName?: string;
    name: string;
    counter?: number;
    avatar?:string;
    ip?:ReactNode;
    clue?:boolean
    status?:number | null;
    date?: string;
    type?: ReactNode;
    onSelect?: (checked:boolean)=>void;
    person?: string;
    clan?: string;
    travel?:string;
    accommodations?: string;
    meal?: string;
    mobility?: string;
    attend?: string;
    isSelected?:boolean;
    isDelete?:boolean;
    onDelete?: ()=>void
}

const ParticipantItem:FC<IParticipantItem> = ({
                                                  id,
                                                  userName='',
                                                  type,
                                                  isSelected=false,
                                                  isCheck=true,
                                                  onSelect= ()=>{},
                                                  avatar,
                                                  name,
                                                  status,
                                                  ip,
                                                  date,
                                                  person,
                                                  clan,
                                                  travel,
                                                  accommodations,
                                                  meal,
                                                  mobility,
                                                  attend,
                                                  isDelete=false,
                                                  onDelete=()=>{}
                                              }) => {
    const [open, setOpen] = useState<boolean>(false)

    const changeOpen = ()=>{
        if(clan || travel || meal || mobility || accommodations || person){
            setOpen(!open)
        }
    }

    return (
        <div className={`participant-item ${open ? "active": ""}`} >
            <div className="participant-item__wrapper">
                {isCheck && <div className="participant-item__select">
                    <Checkbox checked={isSelected} onChange={onSelect}/>
                </div>}
                <div className={"participant-item__inner"} onClick={changeOpen}>
                    <div className="participant-item__id">ID:{id}</div>
                    <div className="participant-item__avatar"><Avatar name={name} image={avatar} /></div>
                    <div className="participant-item__user-name">{userName}</div>
                    <div className="participant-item__name">{name}</div>
                    {status && <div className="participant-item__status"><Tag name={getStatusParticipant(status).name} color={getStatusParticipant(status).color}/></div>}
                    <div className="participant-item__ip">{ip}</div>
                    {attend && <div className="participant-item__status"><Tag name={getTypeAttend(attend).name} color={getTypeAttend(attend).color}/></div>}
                    {type && <div className="participant-item__type">{type}</div>}
                    <div className="participant-item__date"><Tag name={`${date}`} color={"#104100"}/></div>
                    <div className="participant-item__controls">

                        {status &&   <a><EditIcon/></a>}
                        {isDelete &&   <a onClick={onDelete}><DeleteIcon/></a>}
                    </div>
                </div>

            </div>
            <div className="participant-item__content">
                <div className="participant-item__content-wrapper">
                    <div className="participant-item__content-header">Details</div>
                    <div className="participant-item__content-inner">
                        <div className="participant-item__content-item participant-item__user-name"><span>Name:</span> {userName}</div>
                        {status && <div className="participant-item__content-item participant-item__status"><Tag name={getStatusParticipant(status).name} color={getStatusParticipant(status).color}/></div>}
                        {attend && <div className="participant-item__content-item participant-item__status"><Tag name={getTypeAttend(attend).name} color={getTypeAttend(attend).color}/></div>}
                        {type && <div className="participant-item__content-item participant-item__type">{type}</div>}
                        <div className="participant-item__content-item"><span>Clan:</span> {clan} </div>
                        <div className="participant-item__content-item"><span>Travel:</span> {travel} </div>
                        <div className="participant-item__content-item"><span>Accommodations:</span> {accommodations} </div>
                        <div className="participant-item__content-item"><span>Meal:</span> {meal} </div>
                        <div className="participant-item__content-item"><span>Mobility:</span> {mobility}</div>
                        <div className="participant-item__content-item"><span>Person:</span> {person}</div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default ParticipantItem;