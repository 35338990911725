import {FC} from 'react';
import useAuthCheck from "../../hooks/useAuthCheck";
import {Navigate, useLocation} from "react-router-dom";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaLogin} from "../../helpers/schemas";
import {asyncLoginAction} from "../../redux/slices/auth/authAction";
import InputGradient from "../../components/molecules/InputGradient/InputGradient";
import RectButtonGradient from "../../components/atoms/RectButtonGradient/RectButtonGradient";

const Login:FC = () => {
    const isNotAuth = useAuthCheck();
    const location = useLocation();
    const {error} = useSelectorEx(state => state.auth)
    const dispatch = useDispatchEx();
    const {errors,touched,values,handleChange,handleBlur,handleSubmit,setFieldValue} = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        enableReinitialize: true,
        validationSchema:valSchemaLogin,
        onSubmit: (value) => formHandle(value),
    });
    if(!isNotAuth) return <Navigate to={'/admin/participants/'} state={{from: location}} replace />

    const formHandle = (values: any)=>{
        dispatch(asyncLoginAction(values));
    };
    return (
        <div className={'login'}>
            <form onSubmit={handleSubmit}>
                <div className="login__header">Login to admin panel</div>
                <div className="login__item">
                    <InputGradient name={'login'} placeholder={'Login'} onChange={handleChange} onBlur={handleBlur} value={values.login} ErrorMessage={errors.login && touched.login && errors.login}/>
                </div>
                <div className="login__item">
                    <InputGradient name={'password'} type={'password'} placeholder={'Password'} onChange={handleChange} onBlur={handleBlur} value={values.password} ErrorMessage={errors.password && touched.password && errors.password}/>
                    {error && <p style={{color:"#ff0000"}}>{error}</p>}
                </div>
                <div className="login__button">
                    <RectButtonGradient type={'button'} className={'btn-rect-gradient-long'} name={'Sign In'} />
                </div>
            </form>
        </div>
    );
};

export default Login;