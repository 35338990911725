import {FC} from 'react';
import MailSentContainer from "../../components/containers/MailSentContainer";

const MailSentList:FC = () => {
    return (
        <div className={'main-admin__content'}>
            <div className="main-admin__header">
                <h1 style={{color:"#ffffff",marginBottom:"20px"}}>List sent mails</h1>
            </div>
            <MailSentContainer/>
        </div>
    );
};

export default MailSentList;