import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";


import {
    asyncDeleteParticipantAction,
    asyncUpdateParticipantAction, asyncGetParticipantsAction, asyncSendPersonalMailAction, asyncGetSentMailsAction
} from "./participantsAction";
import {IParticipant} from "../../../helpers/types";
import {toast} from "react-toastify";
import {tostSetting} from "../../../helpers/scripts";


interface participantsState {
    participants: IParticipant[] ;
    totalParticipants:number;
    participant: IParticipant | null;
    currentPage: number;
    selectedCount: number;
    isLoading: boolean;
    error: string
}

const initialState: participantsState = {
    participants: [],
    totalParticipants:0,
    participant: null,
    currentPage: 1,
    selectedCount: 0,
    isLoading: false,
    error: ''
};


const participantsSlice = createSlice({
    name:'participantsSlice',
    initialState,
    reducers:{
        setSelectedParticipants(state,action){
            const selectedList =action.payload ;
            state.participants = state.participants.map(item=>{
                    if(selectedList?.includes(item.email as string)) {
                        return {
                            ...item,
                            isSelected: true
                        }
                    }
                    else{
                        return {
                            ...item,
                            isSelected: false
                        }
                    }
            });
        },
        setSelectedCount(state,action){
            state.selectedCount = action.payload;
        },
        setCurrentPageParticipants(state,action){
            state.currentPage = action.payload;
        },
        resetParticipants(state){
            state = initialState;
        }
    },
    extraReducers: (builder:any) => {
        builder
        .addCase(asyncGetParticipantsAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalParticipants = count;
            const oldItems:string = localStorage.getItem('selectedEmail') ?? '';
            let oldArraySelected:string[] = oldItems ? oldItems?.split(',') : [];
            state.participants = rows ? rows.map((item:any)=>{
                if(oldArraySelected?.includes(item.email as string)) {
                    return {
                        ...item,
                        isSelected: true
                    }
                }
                else{
                    return {
                        ...item,
                        isSelected: false
                    }
                }
            }) : [];
           // state.participants = rows ? rows: [];
        })
        .addCase(asyncGetParticipantsAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncGetParticipantsAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(asyncUpdateParticipantAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.participant = action.payload;
            toast.success("Participant successfully updated",tostSetting);
        })
        .addCase(asyncUpdateParticipantAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncUpdateParticipantAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(asyncDeleteParticipantAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.participants = state.participants.filter((item:IParticipant)=>item.id !== action.payload.id);
            toast.success("Participant successfully deleted",tostSetting);
        })
        .addCase(asyncDeleteParticipantAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncDeleteParticipantAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
            toast.error("Cant delete participant",tostSetting)
        })
        .addCase(asyncSendPersonalMailAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            toast.success('This/these letters were sent', tostSetting);
        })
        .addCase(asyncSendPersonalMailAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncSendPersonalMailAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
            toast.error('Cant send personal letter', tostSetting);
        })
        .addCase(asyncGetSentMailsAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalParticipants = count;
            state.participants = rows ? rows: [];
        })
        .addCase(asyncGetSentMailsAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncGetSentMailsAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })
    }
});

export const {setSelectedParticipants,setSelectedCount,setCurrentPageParticipants,resetParticipants} = participantsSlice.actions;
export default participantsSlice.reducer;