import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {asyncGetSentMailsAction} from "../../redux/slices/participants/participantsAction";
import Preloader from "../molecules/Preloader/Preloader";
import ParticipantsList from "../molecules/ParticipantsList/ParticipantsList";
import {useParams} from "react-router-dom";
import {setCurrentPageParticipants, setSelectedCount} from "../../redux/slices/participants/participantsSlice";
import Tag from "../atoms/Tag/Tag";
import {getTypeMailSent} from "../../helpers/functions";

const MailSentContainer:FC = () => {
    const {isLoading, participants,totalParticipants,currentPage} = useSelectorEx(state => state.participants);
    const { pageNumber } = useParams();
    const dispatch = useDispatchEx();
    useEffect(()=>{
        const newPageNumber = pageNumber ? pageNumber: 1;
        dispatch(asyncGetSentMailsAction({
            page: newPageNumber
        }));
        dispatch(setCurrentPageParticipants(Number(newPageNumber)));
   //     localStorage.removeItem('selectedEmail')
    },[]);

    const onPagination = (currentPage:number) =>{
           dispatch(asyncGetSentMailsAction({
               page: currentPage
           }));
        dispatch(setCurrentPageParticipants(Number(currentPage)));
    }

    const onCheckSelect = (email:string) =>{
        const oldItems:string = localStorage.getItem('selectedEmail') ?? '';
        let oldArraySelected:string[] = oldItems ? oldItems?.split(',') : [];
        if(oldArraySelected?.includes(email)){
            oldArraySelected = oldArraySelected.filter(item => item !== email)
        }
        else{
            oldArraySelected.push(email)
        }
        const newSelected = oldArraySelected.join(',');
        localStorage.setItem('selectedEmail', newSelected)
    }

    const mailSentListItems = participants.map((item:any)=> {
        return {
            id: item.id,
            email: item.email,
            ip: <Tag name={item.status} color={item.status === "done" ? "#ce7510":"#e60000"}/>,
            type: <Tag name={getTypeMailSent(item.type).name} color={getTypeMailSent(item.type).color}/>,
            createdAt: item.createdAt
        }
    })


    if(isLoading) return <Preloader/>;
    return (
        <ParticipantsList

            isCheck={false}
            onCheckSelect={onCheckSelect}
            items={mailSentListItems}
            totalCount={totalParticipants}
            onChangePagination={onPagination}
            currentPage={currentPage}
            pageUrl={'/admin/sent'}
        />
    );
};

export default MailSentContainer;