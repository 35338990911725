import React, { useEffect } from 'react';
import {Route, Routes} from "react-router-dom";
import RequireAuth from "./hoc/RequireAuth";
import AdminRoutes from "./routes/AdminRoutes";
import { useDispatchEx, useSelectorEx } from './hooks/redux';
import { asyncCheckAction } from './redux/slices/auth/authAction';
import Login from "./pages/Admin/Login";
import OrdersPage from "./pages/Admin/OrdersPage";
import SettingsMailPage from "./pages/Admin/SettingsMailPage";
import MailSentListPage from "./pages/Admin/MailSentListPage";

function App() {
    const dispatch = useDispatchEx();
    const {user} = useSelectorEx(state => state.auth);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !user) {
            dispatch(asyncCheckAction());
        }
    }, [dispatch, user]);
  return (
    <>
      <Routes>
          <Route path="/" element={<Login/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route element={<RequireAuth role={"ADMIN"} />}>
              <Route path="/admin" element={<AdminRoutes />}>
                  <Route path="participants">
                      <Route path="page/:pageNumber" element={<OrdersPage/>} />
                      <Route path="category">
                          <Route path=":category">
                              <Route path="page/:pageNumber" element={<OrdersPage/>} />
                              <Route index element={<OrdersPage/>} />
                          </Route>
                      </Route>
                      <Route index element={<OrdersPage/>} />
                  </Route>
                  <Route path="mail">
                      <Route index element={<SettingsMailPage/>} />
                  </Route>
                  <Route path="sent">
                      <Route path="page/:pageNumber" element={<MailSentListPage />} />
                      <Route index element={<MailSentListPage/>} />
                  </Route>
                  <Route index element={<OrdersPage />} />

              </Route>
          </Route>
      </Routes>
    </>
  );
}

export default App;
