import './Tag.scss';
import {FC} from 'react';
interface ITag {
    name: string;
    color: string;
    close?:boolean;
    onClose?: () => void;
}


const Tag:FC<ITag> = ({ name, color, close=false, onClose=()=>{} }) => {
    return (
        <span  style={{position: 'relative', backgroundColor: color, color: '#fff', padding: `3px ${close ? "13px": "10px"}`, borderRadius: '20px', fontSize:'12px' }}>
      {name}
            {close && <div style={{position: 'absolute', top:'3px',right:'5px',cursor:'pointer'}} onClick={onClose}>x</div>}
    </span>
    );
};

export default Tag;