import {FC} from 'react';
import SettingsMailForm from "../../components/molecules/SettingsMailForm/SettingsMailForm";
import RectButtonGradient from "../../components/atoms/RectButtonGradient/RectButtonGradient";

const SettingsMailPage:FC = () => {
    return (
        <div className={'main-admin__content'}>
            <h1 style={{color:"#ffffff",marginBottom:"20px"}}>Email sending</h1>
            <div className="settings-form">
                <SettingsMailForm/>
            </div>
        </div>
    );
};

export default SettingsMailPage;