import instance from "./api";
import {AxiosResponse} from 'axios';
import { IParticipant} from "../helpers/types";

export default class ParticipantsService {
    static async findAllParticipants(sort?:string,limit?:number,page?:number,attendType?:string): Promise<AxiosResponse<any>> {
        return instance.get<IParticipant[]>('participants/',{
            params: {
                sort,
                limit,
                page,
                attendType
            }
        } )
    }

    static async create(participant:FormData): Promise<AxiosResponse<IParticipant>> {
        return instance.post<IParticipant>(`participants`,participant)
    }

    static async update(participant:FormData,participantID:number): Promise<AxiosResponse<IParticipant>> {
        return instance.put<IParticipant>(`participants/${participantID}`,participant)
    }

    static async delete(participantID:number): Promise<AxiosResponse<IParticipant>> {
        return instance.delete<IParticipant>(`participants/${participantID}`)
    }

    static async sendPersonalMail(settings:any): Promise<AxiosResponse<any>> {
        return instance.post<any>(`participants/sendPersonalMail`, {
            emails: settings.emails,
            theme: settings.theme,
            message: settings.message,
        })
    }

    static async findAllSentMails(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<any>> {
        return instance.get<any[]>('participants/mails/allMails',{
            params: {
                sort,
                limit,
                page
            }
        } )
    }
}