import './Checkbox.scss';
import {ChangeEvent, FC, useEffect, useState} from 'react';
import CheckIcon from "../icons/CheckIcon";

interface ICheckbox {
    active?: boolean;
    name?: string;
    label?: string;
    id?: string;
    value?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    onChange?: (checked:boolean)=>void;
}

const Checkbox:FC<ICheckbox> = ({active=true, defaultChecked = false,checked = false, value = '',name='',label='',id='',onChange=(e)=>{}}) => {
    const [check,setCheck] = useState(false);
    const onChangeEx = (e:any) =>{
        setCheck(!check);
        onChange(check);
    }

    useEffect(() => {
        setCheck(checked)
    }, [checked]);

    return (
        <div className={`checkbox ${!active ? "disabled": ""}`}>
            <label htmlFor={id}>
                <input name={name}
                       value={value}
                       disabled={!active}
                    // defaultChecked={defaultChecked}
                       checked={check}
                       type="checkbox"
                       onChange={onChangeEx}
                       id={id}/>
            <CheckIcon/>
            </label>
        </div>
    );
};

export default Checkbox;