import './Sidebar.scss';
import {FC} from 'react';
import InboxIcon from "../../atoms/icons/InboxIcon";
import LogoutIcon from "../../atoms/icons/LogoutIcon";
import DashboardIcon from "../../atoms/icons/DashboardIcon";
import {NavLink} from "react-router-dom";
import {useDispatchEx} from "../../../hooks/redux";
import {asyncLogoutAction} from "../../../redux/slices/auth/authAction";

import EnvelopeIcon from "../../atoms/icons/EnvelopeIcon";
interface NavLinkClassNames {
    isActive: boolean;
    isPending: boolean;
}
const Sidebar:FC = () => {
    const dispatch = useDispatchEx();
    const getNavLinkClassNames = ({ isActive, isPending }: NavLinkClassNames): string => {
        return isPending ? "pending" : isActive ? "active" : "";
    };
    return (
        <aside className={'sidebar'}>
            <div className="sidebar__container">
                <div className="sidebar__header">
                    Admin
                </div>
                <div className="sidebar__wrapper">

                    <ul className="sidebar__list">
{/*                        <li className="sidebar__list-item active">
                            <NavLink className={getNavLinkClassNames} to={'/admin/dashboard'}>
                                <DashboardIcon/>
                                <span >Home</span>
                            </NavLink>
                        </li>*/}
                        <li className="sidebar__list-item">
                            <NavLink className={getNavLinkClassNames} to={'/admin/participants'}>
                                <InboxIcon/>
                                <span>Emails list</span>
                            </NavLink>
                        </li>
                        <li className="sidebar__list-item">
                            <NavLink className={getNavLinkClassNames} to={'/admin/mail'}>
                                <EnvelopeIcon/>
                                <span >Email sending</span>
                            </NavLink>
                        </li>
                        <li className="sidebar__list-item">
                            <NavLink className={getNavLinkClassNames} to={'/admin/sent'}>
                                <EnvelopeIcon/>
                                <span >Sent mails</span>
                            </NavLink>
                        </li>
                        <li className="sidebar__list-item">
                            <a onClick={()=>dispatch(asyncLogoutAction())}>
                                <LogoutIcon/>
                                <span >Logout</span>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </aside>
);
};

export default Sidebar;