import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {
    asyncDeleteParticipantAction,
    asyncGetParticipantsAction
} from "../../redux/slices/participants/participantsAction";
import Preloader from "../molecules/Preloader/Preloader";
import ParticipantsList from "../molecules/ParticipantsList/ParticipantsList";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    setCurrentPageParticipants,
    setSelectedCount,
    setSelectedParticipants
} from "../../redux/slices/participants/participantsSlice";

const ParticipantsContainer:FC = () => {
    const {isLoading, participants,totalParticipants,currentPage} = useSelectorEx(state => state.participants);
    const { pageNumber,category } = useParams();
    const dispatch = useDispatchEx();

    useEffect(()=>{
        const newPageNumber = pageNumber ? pageNumber: 1;
        dispatch(asyncGetParticipantsAction({
            page: newPageNumber,
            attendType: category ? Number(category) : null
        }));
        dispatch(setCurrentPageParticipants(Number(newPageNumber)));
      //  dispatch(setSelectedCount(0))
      //  localStorage.removeItem('selectedEmail')
    },[pageNumber,category]);

    const onPagination = (currentPage:number) =>{

           dispatch(asyncGetParticipantsAction({
               page: currentPage,
               attendType: category ? category : null
           }));
        dispatch(setCurrentPageParticipants(Number(currentPage)));
    }

    const onCheckSelect = (email:string) =>{
        const oldItems:string = localStorage.getItem('selectedEmail') ?? '';
        let oldArraySelected:string[] = oldItems ? oldItems?.split(',') : [];
        if(oldArraySelected?.includes(email)){
            oldArraySelected = oldArraySelected.filter(item => item !== email)
        }
        else{
            oldArraySelected.push(email)
        }
        const newSelected = oldArraySelected.join(',');
        localStorage.setItem('selectedEmail', newSelected)
        dispatch(setSelectedCount(oldArraySelected.length))
        dispatch(setSelectedParticipants(oldArraySelected))
    }

    const onDelete = (id:number) =>{
        console.log('onDelete',id);
        dispatch(asyncDeleteParticipantAction(id))
    }

    if(isLoading) return <Preloader/>;
    return (
        <ParticipantsList
            isDelete={true}
            onDelete={onDelete}
            onCheckSelect={onCheckSelect}
            items={participants}
            totalCount={totalParticipants}
            onChangePagination={onPagination}
            currentPage={currentPage}
            pageUrl={`/admin/participants${category ? `/category/${category}`: ''}`}
        />
    );
};

export default ParticipantsContainer;