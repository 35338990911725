import {FC, useCallback, useState} from 'react';
import ParticipantsContainer from "../../components/containers/ParticipantsContainer";
import RectButtonGradient from "../../components/atoms/RectButtonGradient/RectButtonGradient";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {
    setCurrentPageParticipants,
    setSelectedCount,
    setSelectedParticipants
} from "../../redux/slices/participants/participantsSlice";
import {asyncGetParticipantsAction} from "../../redux/slices/participants/participantsAction";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {removeExistingParamsArrayValue} from "../../hooks/routerHooks";

const OrdersPage:FC = () => {
    const {selectedCount,participants} = useSelectorEx(state => state.participants)
    const dispatch = useDispatchEx();
    const onSelectAll = () =>{
        const oldItems:string = localStorage.getItem('selectedEmail') ?? '';
        let oldArraySelected:string[] = oldItems ? oldItems?.split(',') : [];
        let countChanges:number = 0;
        if(participants?.length){

                participants.forEach(participant =>{
                    if(!oldArraySelected?.includes(participant.email as string)){
                        countChanges++;
                        oldArraySelected.push(participant.email as string)
                    }
                })
                if(!countChanges){
                    participants.forEach(participant =>{
                        if(oldArraySelected?.includes(participant.email as string)){
                            oldArraySelected = oldArraySelected.filter(item=> item !== participant.email as string)
                        }
                    })
                }



        }



        const newSelected = oldArraySelected.join(',');
        localStorage.setItem('selectedEmail', newSelected)
        dispatch(setSelectedCount(oldArraySelected.length))
        dispatch(setSelectedParticipants(oldArraySelected))
    }
   // let [searchParams, setSearchParams] = useSearchParams();
    const { category } = useParams();

    const onClearSelection = ()=>{
          dispatch(setSelectedCount(0));
        dispatch(setSelectedParticipants([]))
          localStorage.removeItem('selectedEmail')
    }

    return (
        <div className={'main-admin__content'}>
            <div className="main-admin__header">
            <h1 style={{color:"#ffffff",marginBottom:"20px"}}>List emails</h1>
               <div className="selected-info">
                    <span>Current selected: {selectedCount}</span>
                    <RectButtonGradient href={'/admin/mail'} className={'btn-rect-gradient-long'} name={'Mail to selected'} />
                   <RectButtonGradient onClick={onClearSelection} className={'btn-rect-gradient-long'} name={'Clear all'} />
                </div>
            </div>
            <div className="main-admin__filters">
                <div className={'main-admin__buttons'}>
                    <RectButtonGradient onClick={()=>onSelectAll()} className={'btn-rect-gradient-long'} name={'Select all'} />
                    <RectButtonGradient type={'htmlLink'} href={`${process.env.REACT_APP_URL_SERVER}/api/participants/export`} className={'btn-rect-gradient-long'} name={'Export CSV'} />
                </div>

                <div className="main-admin__filters-wrapper">
                    <h2 style={{color:"#ffffff",marginBottom:"20px"}}>Filters:</h2>
                    <div className="main-admin__filters-list">
                        <RectButtonGradient href={'/admin/participants/'} className={`btn-rect-gradient-long ${!category ? "active" : ""}`} name={'All'} />
                        <RectButtonGradient href={'/admin/participants/category/1'} className={`btn-rect-gradient-long ${category === "1" ? "active" : ""}`} name={'Learn-More'} />
                        <RectButtonGradient href={'/admin/participants/category/2'}   className={`btn-rect-gradient-long ${category === "2" ? "active" : ""}`} name={'One-camp'} />
                        <RectButtonGradient href={'/admin/participants/category/3'}  className={`btn-rect-gradient-long ${category === "3" ? "active" : ""}`} name={'Wedding/Dinner'} />
                    </div>
                </div>

            </div>
            <ParticipantsContainer/>
        </div>
    );
};

export default OrdersPage;