
export function getTimeDate(dateString:string) {
    const date = new Date(dateString);
    return ("0" + (date.getHours())).slice(-2)+':'+("0" + (date.getMinutes())).slice(-2)+':'+("0" + (date.getSeconds())).slice(-2)+' '+("0" + (date.getDate())).slice(-2)+'.'+("0" + (date.getMonth()+1)).slice(-2)+'.'+date.getFullYear();
}


export function getStatusParticipant(status:number):any {
    switch (status) {
        case 1:
            return {color: '#ce7220', name: 'welcome email'};
        case 2:
            return {color: '#237f2e', name: 'estimate email'};
        case 3:
            return {color: '#DC3545FF', name: 'one more'};
        default:
            return {color: '', name: ''};
    }
}

export function replaceEnterWithBr(text:string) {
    return text.replace(/\n/g, '<br/>');
}

export function replaceAndBoldText(text:string) {
    // Заменяем переносы строки на <br/>
    text = text.replace(/\n/g, '<br/>');
    // Заменяем *текст* на <b>текст</b>
    text = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
    return text;
}


export function replaceBrWithEnter(text:string) {
    return text.replace(/<br\s*\/?>/g, '\n');
}

export function getRandomColor(name: string | undefined): string {
    const initial = name?.charAt(0).toUpperCase();
    const colors: string[] = [
        '#F44336', // red
        '#E91E63', // pink
        '#9C27B0', // purple
        '#673AB7', // deep purple
        '#3F51B5', // indigo
        '#2196F3', // blue
        '#03A9F4', // light blue
        '#00BCD4', // cyan
        '#009688', // teal
        '#4CAF50', // green
        '#8BC34A', // light green
        '#CDDC39', // lime
        '#FFEB3B', // yellow
        '#FFC107', // amber
        '#FF9800', // orange
        '#FF5722', // deep orange
        '#795548', // brown
        '#9E9E9E', // grey
        '#607D8B', // blue grey
    ];
    const index: number = initial ? initial.charCodeAt(0) % colors.length : 0;
    return colors[index];
}

export function getTypeMailSent(status:number):any {
    switch (status) {
        case 1:
            return {color: '#4fc100', name: 'estimate-letter'};
        case 2:
            return {color: '#0060c0', name: 'personal-letter'};
        case 3:
            return {color: '#9C27B0', name: 'welcome-letter'};
        case 4:
            return {color: '#4CAF50', name: 'estimate-letter'};
        default:
            return {color: '', name: ''};
    }
}

export function getTypeAttend(status:string):any {
    switch (status) {
        case "Join Ready Playa One Camp":
            return {color: '#e776ff', name: 'One-camp'};
        case "Join the Wedding + Dinner":
            return {color: '#2796c7', name: 'Wedding/Dinner'};
        case "Here to Learn More":
            return {color: '#ff5102', name: 'Learn-More'};

        default:
            return {color: '#607D8B', name: 'No data'};
    }
}