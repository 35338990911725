import './SettingsMailForm.scss';
import {FC, useEffect, useState} from 'react';
import RectButtonGradient from "../../atoms/RectButtonGradient/RectButtonGradient";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {useFormik} from "formik";

import {getRandomColor, replaceAndBoldText, replaceEnterWithBr} from "../../../helpers/functions";
import Tag from "../../atoms/Tag/Tag";
import {asyncSendPersonalMailAction} from "../../../redux/slices/participants/participantsAction";

const SettingsMailForm:FC = () => {
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const dispatch = useDispatchEx();

    const {errors,touched,values,handleChange,handleBlur,handleSubmit,setFieldValue,resetForm} = useFormik({
        initialValues: {
            emails: '',
            theme: '',
            message: '',
        },
        validationSchema:null,
        onSubmit: values => formHandle(values),
    });

    const formHandle = (values:any)=>{
        if(values.message !== '' && values.theme !== ''){
            console.log(values);
            if(values.emails !== '' || selectedEmails){
                dispatch(asyncSendPersonalMailAction({
                    theme: values.theme,
                    emails: values.emails ? values.emails : selectedEmails.join(','),
                    message: replaceAndBoldText(values.message),
                }));
            }

            resetForm()
        }
    }

    useEffect(() => {
        const selectedEmailsList = localStorage.getItem('selectedEmail') ?? '';
        let oldArraySelected:string[] = selectedEmailsList ? selectedEmailsList?.split(',') : [];

        if(oldArraySelected.length > 0) {
            setSelectedEmails(oldArraySelected);
        }
    }, []);

    const onDelete = (deleteItem:string) => {
        setSelectedEmails(prevState => {
            return prevState.filter(item=>item !== deleteItem)
        });
        localStorage.setItem('selectedEmail',selectedEmails.filter(item=>item !== deleteItem).join(','))
    }

    return (
        <div className="settings-form__group">
            <form onSubmit={handleSubmit}>
                <div className="settings-form__input">
                    {selectedEmails.length > 0 ?
                        selectedEmails.map((item)=>{
                            return <Tag key={item} close={true} onClose={()=>onDelete(item)} name={item} color={getRandomColor(item)}/>
                        })
                        :  <input name={'emails'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.emails}
                                  autoComplete={'off'}
                                  type="text" placeholder={'Email'} />}

                </div>
                <div className="settings-form__input">
                    <input
                        autoComplete={'off'}
                        onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.theme} name={'theme'} type="text" placeholder={'Theme'} />
                </div>
                <div className="settings-form__input">
                    <textarea
                        autoComplete={'off'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        name="message" id="" ></textarea>
                </div>
                <div className="settings-form__button">

                    <RectButtonGradient type={'button'} className={'btn-rect-gradient-long'} name={'Send'} />
                </div>
            </form>
        </div>
    );
};

export default SettingsMailForm;